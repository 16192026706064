import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import "../assets/css/post.css"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import "../assets/css/breadcrumb.css"
import 'react-quill/dist/quill.snow.css'

import Contact from "sections/contact";
/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.admin.blog
    if(!post) return null;
    return (
        <>
            <Breadcrumb location={location} crumbLabel="Blog" />
            <div className="container">
                    <article className="content">
                        { post.coverImage ?
                            <figure className="post-feature-image">
                                <img src={ post.coverImage.base64 } alt={ post.coverImage.name } />
                            </figure> : null }
                        <section className="post-full-content">
                            <h1 className="content-title">{post.title}</h1>

                            {/* The main post content */ }
                            <section
                                className="content-body load-external-scripts"
                                dangerouslySetInnerHTML={{ __html: post.content }}
                            />
                        </section>
                    </article>
                </div>
            <Contact/>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        blog: PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
            coverImage: PropTypes.object,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query BlogQuery($slug: String) {
        admin {
            blog(slug: $slug) {
                id
                content
                slug
                coverImage {
                    name
                    base64
                }
                title
            }
        }
    }
`
const styles = {
    breadCrumb:{
        backgroundColor: 'red',
        ol:{
            display: 'flex',
            listStyle: 'none !important'
        }
    }
};
